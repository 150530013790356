import React, { useState, useEffect } from 'react'
import useMediaQuery from '../hooks/useMediaQuery'

const OurPartnersClientsCarousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [transitionEnabled, setTransitionEnabled] = useState(true)

  const length = children.length
  const isTablet = useMediaQuery('(min-width: 768px)')
  const show = isTablet ? '4' : '2'

  const renderExtraNext = () => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(children[index])
    }
    return output
  }

  const handleTransitionEnd = () => {
    if (currentIndex === length) {
      setTransitionEnabled(false)
      setCurrentIndex(0)
    }
  }

  useEffect(() => {
    if (currentIndex < length) {
      setTimeout(() => {
        setCurrentIndex((prevState) => prevState + 1)
      }, 1500)
    }
  }, [currentIndex])

  useEffect(() => {
    if (!transitionEnabled) {
      setTransitionEnabled(true)
    }
  }, [transitionEnabled])

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <div className='carousel-container my-5'>
            <div className='carousel-wrapper'>
              <div className='carousel-content-wrapper'>
                <div
                  className={`carousel-content show-${show}`}
                  style={{
                    transform: `translateX(-${currentIndex * (100 / show)}%)`,
                    transition: !transitionEnabled ? 'none' : undefined,
                  }}
                  onTransitionEnd={() => handleTransitionEnd()}
                >
                  {children}
                  {length > show && renderExtraNext()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurPartnersClientsCarousel
