import React from 'react'
import Typist from 'react-text-typist'
import CTA from './cta'
import HeroTextDesktop from '../images/main-hero/729-hero-text-desktop.png'
import HeroTextTablet from '../images/main-hero/729-hero-text-tablet.png'
import HeroTextMobile from '../images/main-hero/729-hero-text-mobile.png'

const MainHero = () => (
  <section className='main-hero bg-primary d-flex align-items-end'>
    <div className='container d-flex flex-column'>
      <div className='row mt-5'>
        <div className='col d-flex justify-content-center'>
          <picture>
            <source media='(max-width: 767.98px)' srcSet={HeroTextMobile} />
            <source media='(max-width: 1199.98px)' srcSet={HeroTextTablet} />
            <img src={HeroTextDesktop} className='' style={{ maxWidth: '100%' }} alt='Custom design, development, professional service, and AI consulting' />
          </picture>
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col'>
          <h1 className='text-white my-4 text-unset'>
            <small className='d-block'>We help you connect ALL the dots, so you get</small>
          </h1>
          <div className='typing'>
            <Typist
              sentences={['Projects done on time and on budget', 'Systems integrations that no one else can do', 'Seamless business growth']}
              className='text-white'
              loop={true}
            />
          </div>
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col text-center'>
          <CTA href='/services/' text='LEARN MORE' className='btn btn-secondary-light' />
        </div>
      </div>
    </div>
  </section>
)

export default MainHero
