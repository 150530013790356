import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { get } from 'lodash'
import CTA from './cta'

// Get information from the Gatbys tree and get tree porfolio sample.
// are doing this because this is the way to can resolve the images links and src.
const PorfolioHome = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      allProjectsJson(filter: { title: { in: ["Psychiatric Alternatives (PAWC)", "Bundle Loan", "Alta Foodcraft"] } }) {
        nodes {
          title
          categories
          link
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  // Filtering from page.
  const portfolioSample = get(data, 'allProjectsJson.nodes', [])

  return (
    <section className={className}>
      <div className='container text-center'>
        <div className='row '>
          <div className='col'>
            <h2>Our Portfolio</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col d-flex flex-wrap portfolio-feed-home-3'>
            {portfolioSample.map((project) => {
              return (
                <div className='portfolio-item mb-5' key={project.title}>
                  <div className='card linked h-100'>
                    <Link className='d-flex flex-column h-100' to={project.link}>
                      <div className='card-body flex-grow-1 flex-fill pb-6'>
                        <h2>{project.title}</h2>
                        <p className='text-dark'>{project.categories}</p>
                      </div>
                      <div className='card-footer bg-transparent border-0 p-0  flex-shrink-0'>
                        <GatsbyImage alt={project.title} image={getImage(project.src.childImageSharp.gatsbyImageData)} />
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-12'>
            <CTA href='/portfolio/' text='VIEW OUR FULL PORTFOLIO' className='btn btn-primary'></CTA>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PorfolioHome
