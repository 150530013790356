import React from 'react';
import SoftwareDevelopmentIcon from '../images/services/software-development.png';
import ProfessionalServicesIcon from '../images/services/professional-services.png';
import DesignIcon from '../images/services/design.png';
import MaintenanceIcon from '../images/services/maintenance-operations.png';
import CTA from './cta';

const OurServices = ({ className }) => {
  return (
    <section className={className}>
      <div className="container text-center our-services">
        <div className="row ">
          <div className="col">
            <h2>Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12 mb-4">
            <div className="custom-cards mx-auto d-flex flex-column flex-wrap justify-content-center">
              <div className="icon-row"><img src={SoftwareDevelopmentIcon} className="icon" alt="Software Development" /></div>
              <div className="text">Software Development</div>
              <div className="btn-row">
                <CTA
                  href="/development-services/"
                  text="LEARN MORE"
                  className="btn btn-primary">
                </CTA>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 mb-4">
            <div className="custom-cards mx-auto d-flex flex-column flex-wrap justify-content-center">
              <div className="icon-row"><img src={ProfessionalServicesIcon} className="icon" alt="Professional Services" /></div>
              <div className="text">Professional Services</div>
              <div className="btn-row">
                <CTA
                  href="/services/"
                  text="LEARN MORE"
                  className="btn btn-primary">
                </CTA>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 mb-4">
            <div className="custom-cards mx-auto d-flex flex-column flex-wrap justify-content-center">
              <div className="icon-row"><img src={DesignIcon} className="icon" alt="Design" /></div>
              <div className="text">Design</div>
              <div className="btn-row">
                <CTA
                  href="/design-services/"
                  text="LEARN MORE"
                  className="btn btn-primary">
                </CTA>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 mb-4">
            <div className="custom-cards mx-auto d-flex flex-column flex-wrap justify-content-center">
              <div className="icon-row"><img src={MaintenanceIcon} className="icon" alt="Maintenance &amp; Operations" /></div>
              <div className="text">Maintenance &amp; Operations</div>
              <div className="btn-row">
                <CTA
                  href="/maintenance-and-operations/"
                  text="LEARN MORE"
                  className="btn btn-primary">
                </CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurServices;
