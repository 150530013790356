import React from 'react'
import OurPartnersClientsCarousel from '../components/our-partners-clients-carousel'
import AppleGray from '../images/partners/apple-gray.png'
import FacebookGray from '../images/partners/facebook-gray.png'
import UniversalGray from '../images/partners/universal-gray.png'
import AirbnbGray from '../images/partners/airbnb-gray.png'
import ZendeskGray from '../images/partners/zendesk-gray.png'
import PinterestGray from '../images/partners/pinterest-gray.png'
import GithubGray from '../images/partners/github-gray.png'
import AmazonGray from '../images/partners/aws-gray.png'
import NewRelicGray from '../images/partners/new-relic-gray.png'
import RecurlyGray from '../images/partners/recurly-gray.png'
import ShopifyGray from '../images/partners/shopify-gray.png'

const OurPartnersClients = ({ className }) => {
  return (
    <section className={className}>
      <div className='row'>
        <div className='col-lg-4 col-2 text-partner text-center'>
          <hr className='ml-lg-6' />
        </div>
        <div className='col-lg-4 col-8 title text-partner text-center'>Our Partners &amp; Clients</div>
        <div className='col-lg-4 col-2 text-partner text-center'>
          <hr className='mr-lg-6' />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <OurPartnersClientsCarousel>
            <div>
              <img src={AppleGray} alt='Apple' />
            </div>
            <div>
              <img src={FacebookGray} alt='Facebook' />
            </div>
            <div>
              <img src={UniversalGray} alt='Universal Music' />
            </div>
            <div>
              <img src={AirbnbGray} alt='Airbnb' />
            </div>
            <div>
              <img src={ZendeskGray} alt='Zendesk' />
            </div>
            <div>
              <img src={PinterestGray} alt='Pinterest' />
            </div>
            <div>
              <img src={ShopifyGray} alt='Github' />
            </div>
            <div>
              <img src={GithubGray} alt='Github' />
            </div>
            <div>
              <img src={AmazonGray} alt='Amazon' />
            </div>
            <div>
              <img src={NewRelicGray} alt='New Relic' />
            </div>
            <div>
              <img src={RecurlyGray} alt='Recurly' />
            </div>
          </OurPartnersClientsCarousel>
        </div>
      </div>
    </section>
  )
}

export default OurPartnersClients
