import React, { useEffect, useState } from 'react'
import set from 'lodash/set'
import { graphql, useStaticQuery } from 'gatsby'
import FlexSearch from 'flexsearch'
import { searchPosts, getLocalImagesIndex } from '../api'
import BlogFeedEntry from '../components/blog-feed-entry'
import BlogSidebar from '../components/blog-sidebar'
import Layout from '../components/layout'
import MainHero from '../components/main-hero'
import OurServices from '../components/our-services'
import OurPartnersClients from '../components/our-partners-clients'
import Seo from '../components/seo'
import CTA from '../components/cta'
import Quote from '../components/quote'
import PorfolioHome from '../components/porfolio-home'
import CalendarIcon from '../images/experts/calendar.svg'
import SmilleIcon from '../images/experts/smille.svg'
import ToolsIcon from '../images/experts/tools.svg'
import ShakeIcon from '../images/experts/shake.svg'
import RightHandIcon from '../icons/right-hand.svg'
import NextProject from '../components/next-project'

const ShowResults = ({ query, isLoading, results }) => {
  const imageIndexData = useStaticQuery(graphql`
    query {
      localSearchBlogLocalImages {
        publicIndexURL
        publicStoreURL
      }
    }
  `)

  if (isLoading) {
    return (
      <Layout>
        <section>
          <div className='container-fluid pt-6'>
            <div className='row'>
              <div className='col-12'>
                <h2>Loading...</h2>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }

  // Since we are using WP search api to get results,
  // we will need to translate the references of the featured images,
  // so we can use local images instead.
  // In order to do that we are using a local index (flexsearch) that is lazy loaded.
  if (results && results.length) {
    // First try to use the index and store from cache.
    if (imageIndexData.localSearchBlogLocalImages.index && imageIndexData.localSearchBlogLocalImages.store) {
      replaceFeaturedImagesWithLocalImages(results, imageIndexData.localSearchBlogLocalImages.index, imageIndexData.localSearchBlogLocalImages.store)
    } else {
      // Otherwise, if cache is not initialized, we need to request the index and store files.
      const imageIndexUrl = imageIndexData.localSearchBlogLocalImages.publicIndexURL
      const imageStoreUrl = imageIndexData.localSearchBlogLocalImages.publicStoreURL

      getLocalImagesIndex(imageIndexUrl, imageStoreUrl).then((imageIndexResult) => {
        const { index: imageIndex, store: imageStore } = imageIndexResult

        // Create and import flexsearch index.
        const flexSearchIndex = FlexSearch.create()
        flexSearchIndex.import(imageIndex)

        // Set the index and store in the cache.
        imageIndexData.localSearchBlogLocalImages.index = flexSearchIndex
        imageIndexData.localSearchBlogLocalImages.store = imageStore

        replaceFeaturedImagesWithLocalImages(results, imageIndexData.localSearchBlogLocalImages.index, imageIndexData.localSearchBlogLocalImages.store)
      })
    }
  }

  return (
    <Layout>
      <Seo title={`Search Results for ${query}`} />
      <section>
        <div className='container-fluid pt-6'>
          <div className='row'>
            <div className='col-xl-10'>
              <div className='row px-xl-6 mb-5'>
                <div className='col pl-lg-0'>
                  <h1>Search Results for {query}</h1>
                </div>
              </div>
              <div className='row row-cols-1 row-cols-md-2 mb-3 pl-xl-6'>
                {results.length == 0 && <p>No results found for your search chriteria</p>}
                {results.map((post) => (
                  <div key={post.slug} className='col pr-xl-6 mb-xl-6 mb-4 pl-lg-0'>
                    <BlogFeedEntry post={post} />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-xl-2'>
              <BlogSidebar />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const Index = ({ location }) => {
  const [results, setResults] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const searchQuery = params.get('s')
    if (searchQuery && searchQuery.length > 0) {
      setLoading(true)
      searchPosts(searchQuery).then((searchResult) => {
        setResults(searchResult)
        setLoading(false)
      })
    }
  }, [location.search])

  const searchQuery = new URLSearchParams(location.search).get('s')
  if (searchQuery) {
    return <ShowResults query={searchQuery} isLoading={isLoading} results={results} />
  }

  return (
    <Layout>
      <Seo title='Custom design, development, professional services, and AI consulting'></Seo>

      <MainHero />

      {/* We have the experience */}
      <section className='d-flex mb-0'>
        <div className='container py-6 home-section-1'>
          <div className='row'>
            <div className='col'>
              <div className='title text-green-729 text-center'>We have the experience, skills, and expertise to do jobs that no one else can do…</div>
              <div className='subtitle text-green-light-729 text-center'>And our track record proves it</div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Partners & Clients */}
      <OurPartnersClients className={'container home-section-2'} />

      {/* Projects Shouldn't Be A Mystery */}
      <section className='home-section-3 d-flex flex-column justify-content-center mb-6 py-6 text-white text-center'>
        <div className='title'>Projects Shouldn't Be A Mystery</div>
        <div className='subtitle'>That's why we keep you informed and engaged all the way through.</div>
        <div className='text'>
          We didn't like the way we saw some projects run by other development companies. They had bloated budgets, inaccurate timelines, and produced sub-par
          solutions.
        </div>
        <div className='text'>
          <small>We created an Integrated Quality (IQ)</small> framework that helps us deliver higher-quality, better-performing projects on time and on budget.
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col text-center'>
              <CTA href='/iq-process/' text='VIEW OUR IQ PROCESS' className='btn btn-secondary-light' />
            </div>
          </div>
        </div>
      </section>

      {/* Our Services */}
      <OurServices className={'home-section-4 my-5'} />

      {/* What Our Clients Say… */}
      <section className='home-section-5'>
        <div className='container-xl py-5'>
          <div className='row'>
            <div className='col-12 text-white text-center my-4 title'>What Our Clients Say…</div>

            <div className='col-12 col-md-4 my-4 px-5 px-md-4'>
              <Quote
                dark
                company='Lift'
                name='Nathan Haugo'
                quote='"It&apos;s great be able to send the things we don&apos;t have the bandwidth to do to 729. They get it done well."'
              />
            </div>

            <div className='col-12 col-md-4 my-4 px-5 px-md-4'>
              <Quote
                dark
                company='Deeanna Akerson'
                name='Kindred Bravely'
                quote='"They understood our unique workflow and what we were trying to do. I can&apos;t recommend them highly enought."'
              />
            </div>

            <div className='col-12 col-md-4 my-4 px-5 px-md-4'>
              <Quote dark company='Lift' name='Nathan Haugo' quote='"Overhelmingly, the feedback from our whole team was exceletent."' />
            </div>
          </div>
        </div>
      </section>

      {/* Our Portfolio */}
      <PorfolioHome className={'home-section-6 my-5'} />

      {/* Work With The Experts */}
      <section className='home-section-7 mt-5'>
        <div className='container text-center'>
          <div className='row'>
            <div className='col my-4'>
              <h2>Work With The Experts</h2>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-9 col-sm-8 col-md-6 mb-6 px-lg-6 px-md-4'>
              <div className='experts'>
                <div className='icon-row'>
                  <CalendarIcon className='icon' alt='Around since 2003' />
                </div>
                <div className='text text-left'>
                  <p className='line1'>Around since 2003...</p>
                  <p className='line2'>We’re here for the long haul.</p>
                </div>
                <div className='btn-experts'>
                  <CTA href='/about-us/' text='MORE ABOUT US' className='btn btn-primary'></CTA>
                </div>
              </div>
            </div>
            <div className='col-9 col-sm-8 col-md-6 mb-6 px-lg-6 px-md-4'>
              <div className='experts'>
                <div className='icon-row'>
                  <SmilleIcon className='icon' alt='Our happy customers' />
                </div>
                <div className='text text-left'>
                  <p className='line1'>Our happy customers....</p>
                  <p className='line2'>See how we’ve helped them.</p>
                </div>
                <div className='btn-experts'>
                  <CTA href='/portfolio/' text='VIEW PORTFOLIO' className='btn btn-primary'></CTA>
                </div>
              </div>
            </div>
            <div className='col-9 col-sm-8 col-md-6 mb-6 px-lg-6 px-md-4'>
              <div className='experts'>
                <div className='icon-row'>
                  <ToolsIcon className='icon' alt='Need a specific tool?' />
                </div>
                <div className='text text-left'>
                  <p className='line1'>Need a specific tool?</p>
                  <p className='line2'>Check for yours on the list.</p>
                </div>
                <div className='btn-experts'>
                  <CTA href='/development-services/' text='VIEW TOOLS' className='btn btn-primary'></CTA>
                </div>
              </div>
            </div>
            <div className='col-9 col-sm-8 col-md-6 mb-6 px-lg-6 px-md-4'>
              <div className='experts'>
                <div className='icon-row'>
                  <ShakeIcon className='icon' alt='We also have some great partners' />
                </div>
                <div className='text text-left'>
                  <p className='line1'>We also have some great partners…</p>
                  <p className='line2'>Explore how we work with them.</p>
                </div>
                <div className='btn-experts'>
                  <CTA href='/partnerships/' text='MORE ABOUT US' className='btn btn-primary'></CTA>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <NextProject className={'next-project mb-5'} />
    </Layout>
  )
}

const replaceFeaturedImagesWithLocalImages = (results, flexSearchIndex, imageStore) => {
  if (!results || !results.length) return
  if (!flexSearchIndex || !imageStore) return

  results.forEach((post) => {
    const flexSearchIndexResults = flexSearchIndex.search(post.slug)
    const localImages = flexSearchIndexResults.map((id) => imageStore[id])
    if (localImages && localImages.length) {
      set(post, 'featuredImage.node.localFile.publicURL', localImages[0].imageUrl)
    }
  })
}

export default Index
